var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "flex-end"
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["saleCalendarHome:btn:add"],
      expression: "['saleCalendarHome:btn:add']"
    }],
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("添加")])], 1), _c("a-table", {
    staticStyle: {
      "overflow-x": "auto"
    },
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(record) {
        return record.id;
      },
      loading: _vm.loading,
      scroll: {
        x: 1300
      }
    },
    scopedSlots: _vm._u([{
      key: "nickname",
      fn: function fn(nickname, row) {
        return [_c("span", {
          class: {
            blue: row.uid
          },
          on: {
            click: function click($event) {
              return _vm.toUserDetail(row.uid);
            }
          }
        }, [_vm._v(" " + _vm._s(nickname || "-"))])];
      }
    }, {
      key: "createtime",
      fn: function fn(createtime) {
        return [_vm._v(" " + _vm._s(createtime || "") + " ")];
      }
    }, {
      key: "imgurl",
      fn: function fn(imgurl) {
        return [imgurl ? _c("img", {
          staticStyle: {
            width: "80px",
            height: "80px",
            cursor: "pointer"
          },
          attrs: {
            src: imgurl,
            alt: "bg_img"
          },
          on: {
            click: function click($event) {
              return _vm.preview(imgurl);
            }
          }
        }) : _vm._e()];
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return [_c("span", {
          class: {
            green: status
          }
        }, [_vm._v(_vm._s(status ? "生效中" : "失效"))])];
      }
    }, {
      key: "action",
      fn: function fn(actions, row) {
        return [_c("div", {
          staticClass: "flex",
          staticStyle: {
            width: "150px",
            margin: "0 auto"
          }
        }, [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["saleCalendarHome:btn:edit"],
            expression: "['saleCalendarHome:btn:edit']"
          }],
          staticStyle: {
            "min-width": "60px"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("edit", row);
            }
          }
        }, [_vm._v("编辑")]), _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["saleCalendarHome:btn:delete"],
            expression: "['saleCalendarHome:btn:delete']"
          }],
          staticStyle: {
            color: "#ff4d4f",
            "min-width": "60px"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("del", row);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("DPopup", {
    attrs: {
      title: _vm.versionTitle,
      comVisible: _vm.addVersionVisible
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("AddPreRecommend", {
          attrs: {
            currentFormData: _vm.currentFormData,
            updateStatus: _vm.updateStatus,
            editId: String(_vm.editId)
          },
          on: {
            handleAddCancle: _vm.handleAddCancle,
            handleAddSubmit: _vm.handleAddSubmitSuccess
          }
        })];
      },
      proxy: true
    }])
  }), _c("DPopup", {
    attrs: {
      title: "",
      comVisible: _vm.delVisible
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "justify-content": "center"
          }
        }, [_c("p", {
          staticStyle: {
            "font-size": "18px",
            "margin-top": "20px"
          }
        }, [_vm._v("确定删除？")])]), _c("div", {
          staticStyle: {
            display: "flex",
            "justify-content": "flex-end",
            margin: "10px",
            gap: "10px"
          }
        }, [_c("a-button", {
          staticClass: "btn btn_l",
          staticStyle: {
            background: "#f5f5f5",
            border: "0",
            flex: "1"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.cancle
          }
        }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
          staticClass: "btn btn_r",
          staticStyle: {
            "margin-left": "10px",
            flex: "1"
          },
          attrs: {
            size: "large",
            type: "primary"
          },
          on: {
            click: _vm.confirm
          }
        }, [_vm._v(_vm._s("确定"))])], 1)];
      },
      proxy: true
    }])
  }), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };