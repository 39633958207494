var columnsPerson = [{
  title: '推荐ID',
  dataIndex: 'id',
  key: 'id',
  width: '20%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '用户名',
  dataIndex: 'nickname',
  key: 'nickname',
  width: '20%',
  scopedSlots: {
    customRender: 'nickname'
  }
}, {
  title: '创建时间',
  dataIndex: 'createtime',
  key: 'createtime',
  width: '20%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '背景大图',
  dataIndex: 'imgurl',
  key: 'imgurl',
  width: '20%',
  scopedSlots: {
    customRender: 'imgurl'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  width: '10%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columnsPerson };